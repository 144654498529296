@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #0f0f0f;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 400;
  font-style: normal;
}

span,
.primary-color {
  background-image: linear-gradient(to right, #391ca3, #873ac9);
  -webkit-background-clip: text;
  color: transparent;
}

.bg-primary-color {
  background-image: linear-gradient(to right, #391ca3, #873ac9)
}

.custom-tech-span {
  background-color: #27272a; /* Equivalent to bg-neutral-800 */
  border-radius: 0.375rem; /* Equivalent to rounded */
  padding: 0.25rem 0.5rem; /* Equivalent to px-2 py-1 */
  font-size: 0.875rem; /* Equivalent to text-sm */
  font-weight: 500; /* Equivalent to font-medium */
}


